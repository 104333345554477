import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'


const PageLoader = ({loading}) => {
	const LOADING_TEXT = 'loading'
	const [loaderText, setLoaderText] = useState(LOADING_TEXT)

	useEffect(() => {
	  const interval = setInterval(() => {
			setLoaderText(loaderText => {
				if (loaderText.length >= LOADING_TEXT.length + 3) return LOADING_TEXT
				return loaderText + '.'
			})
	  }, 250);
	  return () => clearInterval(interval)
	}, []);

	return (
		<>
			<Helmet>
				<title>rkss - loading</title>
			</Helmet>
			<div className='flex h-screen w-full bg-[#000000] select-none'>
				<div className="m-auto bg-purple-900/50 text-white/50 text-sm px-4 font-bold">
					<div className="flex flex-col max-w-md justify-center center rounded-md">
						{loading ? loaderText : ''}
					</div>
				</div>
			</div>
		</>
	)
}

export default PageLoader