import { OrthographicCamera } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useRef, useState, useEffect, useMemo, useLayoutEffect } from "react";
import * as THREE from "three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { useLoader } from '@react-three/fiber'
import { wrapEffect, EffectComposer } from "@react-three/postprocessing";
import { Effect } from "postprocessing";


const fragmentShader = `
    precision highp float;

    uniform float colorNum;

    const mat2x2 bayerMatrix2x2 = mat2x2(
        0.0, 2.0,
        3.0, 1.0
    ) / 4.0;

    const mat4x4 bayerMatrix4x4 = mat4x4(
        0.0,  8.0,  2.0, 10.0,
        12.0, 4.0,  14.0, 6.0,
        3.0,  11.0, 1.0, 9.0,
        15.0, 7.0,  13.0, 5.0
    ) / 16.0;

    const float bayerMatrix8x8[64] = float[64](
        0.0/ 64.0, 48.0/ 64.0, 12.0/ 64.0, 60.0/ 64.0,  3.0/ 64.0, 51.0/ 64.0, 15.0/ 64.0, 63.0/ 64.0,
    32.0/ 64.0, 16.0/ 64.0, 44.0/ 64.0, 28.0/ 64.0, 35.0/ 64.0, 19.0/ 64.0, 47.0/ 64.0, 31.0/ 64.0,
        8.0/ 64.0, 56.0/ 64.0,  4.0/ 64.0, 52.0/ 64.0, 11.0/ 64.0, 59.0/ 64.0,  7.0/ 64.0, 55.0/ 64.0,
    40.0/ 64.0, 24.0/ 64.0, 36.0/ 64.0, 20.0/ 64.0, 43.0/ 64.0, 27.0/ 64.0, 39.0/ 64.0, 23.0/ 64.0,
        2.0/ 64.0, 50.0/ 64.0, 14.0/ 64.0, 62.0/ 64.0,  1.0/ 64.0, 49.0/ 64.0, 13.0/ 64.0, 61.0/ 64.0,
    34.0/ 64.0, 18.0/ 64.0, 46.0/ 64.0, 30.0/ 64.0, 33.0/ 64.0, 17.0/ 64.0, 45.0/ 64.0, 29.0/ 64.0,
    10.0/ 64.0, 58.0/ 64.0,  6.0/ 64.0, 54.0/ 64.0,  9.0/ 64.0, 57.0/ 64.0,  5.0/ 64.0, 53.0/ 64.0,
    42.0/ 64.0, 26.0/ 64.0, 38.0/ 64.0, 22.0/ 64.0, 41.0/ 64.0, 25.0/ 64.0, 37.0/ 64.0, 21.0 / 64.0
    );

    vec3 dither(vec2 uv, vec3 color) {
    int x = int(uv.x * resolution.x) % 8;
    int y = int(uv.y * resolution.y) % 8;
    float threshold = bayerMatrix8x8[y * 8 + x] - 0.88;

    color.rgb += threshold;
    color.r = floor(color.r * (colorNum - 1.0) + 0.5) / (colorNum - 1.0);
    color.g = floor(color.g * (colorNum - 1.0) + 0.5) / (colorNum - 1.0);
    color.b = floor(color.b * (colorNum - 1.0) + 0.5) / (colorNum - 1.0);

    return color;
    }

    void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    vec4 color = texture2D(inputBuffer, uv);
    color.rgb = dither(uv, color.rgb);

    outputColor = color;
}`

/* eslint-disable react/no-unknown-property */

class RetroEffectImpl extends Effect {
    constructor({ colorNum = 8.0 }) {
      const uniforms = new Map([
        ["colorNum", new THREE.Uniform(4.0)],
      ]);
  
      super("RetroEffect", fragmentShader, {
        uniforms,
      });
  
      this.uniforms = uniforms;
    }
  
    set colorNum(value) {
      this.uniforms.get("colorNum").value = value;
    }
  
    get colorNum() {
      return this.uniforms.get("colorNum").value;
    }
  }
  
const RetroEffect = wrapEffect(RetroEffectImpl);

const Watcher = () => {
    const obj = useLoader(OBJLoader, '/cam.obj');
    const { camera: camera2, size } = useThree();
    const [cursor, setCursor] = useState([window.innerWidth / 2, window.innerHeight / 2]);
    const groupRef = useRef();
    const allRef = useRef();

    const effect = useRef();

    useEffect(() => {
        const handleMouseMove = (event) => {
            setCursor([event.clientX, event.clientY]);
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);


    useEffect(() => {
        const updatePosition = () => {
            const vector = new THREE.Vector3(-1, 1, 0);
            vector.unproject(camera2);

            if (allRef.current) {
                allRef.current.position.set(vector.x + 0.9, vector.y - 1, 10);
            }
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);
        return () => window.removeEventListener('resize', updatePosition);
    }, [camera2, size]);

    useEffect(() => {
        const glass = obj.getObjectByName('Glass');
        if (glass) {
          // Apply the glass material to the Glass object
          glass.material = new THREE.MeshPhysicalMaterial({
            color: 'blue', // Set base color
            
          });
          glass.material.transparent = true; // Enable transparency
        }
    }, [obj]);

    
    useFrame(() => {
        effect.current.colorNum = parseInt(5, 10);
    })

    useFrame(({ camera, size }) => {
        var vec = new THREE.Vector3();
        var pos = new THREE.Vector3();

        console.log(cursor)
        vec.set(
            (cursor[0] / window.innerWidth) * 2 - 1,
            - (cursor[1] / window.innerHeight) * 2 + 1,
            0.5,
        );

        vec.unproject(camera);
        vec.sub(camera.position).normalize();
        var distance = - camera.position.z / vec.z;
        pos.copy(camera.position).add(vec.multiplyScalar(distance));

        if (groupRef.current) {
            groupRef.current.lookAt(new THREE.Vector3(pos.x, pos.y, pos.z + 25))
        }
    });

    
    if (obj.children.length > 0) {
        return (
            <>
                <mesh
                    rotation={[0, 1.4, 0]}
                    position={[-7, 0, 20]}
                    scale={.30}
                    ref={allRef}
                    receiveShadow castShadow
                >
                    <group ref={groupRef} position={[0, -0.4, 0]} rotation={[0, 0, 0]}>
                        <primitive rotation={[0, 0, 0]} object={obj.getObjectByName('lens_body')} material={new THREE.MeshStandardMaterial({color:"red"})}/>
                        <primitive rotation={[0, 0, 0]} object={obj.getObjectByName('Glass')} material={new THREE.MeshPhysicalMaterial({ color: '#dd00ff',metalness: 0.1, roughness: 0.05,  opacity: 1,transmission: 1.0,ior: 0.5, })}  />
                        <primitive rotation={[0, 0, 0]} object={obj.getObjectByName('cam_body')} material={new THREE.MeshStandardMaterial({color:"#642299"})} />
                        <primitive rotation={[0, 0, 0]} object={obj.getObjectByName('rotate_node02')} material={new THREE.MeshStandardMaterial({color:"#c458fe"})} />
                        <primitive rotation={[0, 0, 0]} object={obj.getObjectByName('lens01')} material={new THREE.MeshStandardMaterial({color:"red"})} />
                        <primitive rotation={[0, 0, 0]} object={obj.getObjectByName('rain_cover')} material={new THREE.MeshStandardMaterial({color:"#525151"})}/>
                    </group>
                    <group>
                        <primitive object={obj.getObjectByName('cam_base')} material={new THREE.MeshStandardMaterial({color:"#525151"})} />
                    </group>
                    <meshStandardMaterial color="#c458fe" />

                </mesh>

                <EffectComposer>
                    <RetroEffect ref={effect} />
                </EffectComposer>
            </>
        );
    }
};



const WatcherScene = () => {
    return (
        <>
                <Canvas className="bg-transparent" shadows dpr={[1, 2]}>
                <ambientLight intensity={0.25} />
                <directionalLight position={[0, 10, 5]} intensity={10.5} />
                <OrthographicCamera makeDefault position={[0, 0, 1000]} zoom={120} />

                <Watcher />
            </Canvas>
        </>
    );
};



export default WatcherScene;