import { useAuth } from 'hooks/useAuth'
import { useEffect, useRef, useState } from "react"
import { Helmet } from 'react-helmet-async'
import Modal from 'components/Modal';

import Scene from "./scene"
import WatcherScene from "./watcher"


function Login() {
	const auth = useAuth();
	const inputRef = useRef(null);
	const [show, setShow] = useState(false)
	const [user, setUser] = useState('')
	const [authKey, setAuthKey] = useState('')
	const [statusString, setStatusString] = useState('')

	useEffect(() => {
		if (show) setAuthKey('')
		if (show && inputRef.current) {
			inputRef.current.focus();
		}
	}, [show]);

	const tryLogin = () => {
		setStatusString('Loading...')

		auth.login(user, authKey.toLowerCase()).then(success => {
			if (success) {
				setStatusString('Validated')
			} else {
				setStatusString('Error!')
				setTimeout(() => {
					setAuthKey('')
				}, 1000);
			}
		})
	}


	return (
		<>
			<Helmet>
				<title>rkss - auth</title>
			</Helmet>

			<div className='absolute h-screen w-screen bg-[#000000]' style={{zIndex: -10}}>
				<Scene/>
			</div>

			<div className='absolute h-screen w-screen bg-transparent' style={{zIndex: -10}}>
				<WatcherScene/>
			</div>


			<div className='flex h-screen w-full select-none z-10'>
				<div className="m-auto text-gray-300 bg-purple-900/30 rounded-md py-4 px-8">
					<div className='relative flex flex-row gap-1 justify-center mb-8'>
						<div className='text-xs font-black text-purple-700 leading-tight'>
							<pre>        __                   </pre>
							<pre>_______|  | __  ______ ______</pre>
							<pre>\_  __ \  |/ / /  ___//  ___/</pre>
							<pre> |  | \/    {'<'}  \___ \ \___ \ </pre>
							<pre> |__|  |__|_ \/____  {'>'}____  {'>'}</pre>
							<pre>            \/     \/     \/ </pre>
						</div>

						<div className='absolute bg-purple-700 px-1 rotate-[-15deg] text-xs -right-2 -bottom-2'>
							123456
						</div>
					</div>
					<div className="flex flex-col max-w-md justify-center center rounded-md">
						<div className="px-2 py-1">
							<button onClick={() => setShow(true)} className='border w-full outline-none font-semibold border border-purple-600 hover:bg-purple-700 hover:text-black py-1 text-sm border-gray-900 hover:border-purple-700'>{'> auth'}</button>
						</div>
					</div>
				</div>
			</div>


			<Modal isOpen={show} onDismiss={() => setShow(false)} minHeight={false} maxHeight={90} initialFocusRef={inputRef}>
				<div className='relative bg-[#0b0b0b] p-4 text-gray-300 bg-black'>
					<button className='absolute top-2 right-4 font-bold z-10 text-xl select-none outline-0 text-purple-700' onClick={() => setShow(false)}>
						X
					</button>
					<div className='absolute top-3 left-4 uppercase text-base font-bold'>Auth</div>

					<div className='flex flex-col mt-5 gap-2'>
						<hr className='border-purple-800/50 my-2'></hr>
						<div>{statusString}</div>
						<input ref={inputRef} className='text-sm px-3 py-1 border border-purple-800 outline-none text-base-200 w-full bg-transparent placeholder-base-300 ' value={user} onChange={e => setUser(e.target.value)} placeholder='User'></input>
						<input className='text-sm px-3 py-1 border border-purple-800 outline-none text-base-200 w-full bg-transparent placeholder-base-300 ' value={authKey} onChange={e => setAuthKey(e.target.value)} placeholder='Pass'></input>
						<button className='border px-4 py-1 font-semibold text-sm bg-purple-700 text-black border-purple-700 w-full' onClick={() => tryLogin()}>Login</button>
					</div>

				</div>
			</Modal>
		</>

	);
}

export default Login;